
  export default {
    inject: ['$validator'],
    components: {
      MPowerValidateInput: () => import('./MPowerValidateInput'),
    },
    data() {
      return {
        showPassword: false,
      };
    },
    props: {
      value: {},
      validate: String | Object,
      dataVvAs: String,
      autocomplete: {
        type: String,
        default: 'off',
      },
      name: {
        type: String,
        default: 'password',
      },
      label: {
        type: String,
        default: 'Password',
      },
    },
    computed: {
      val: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
  };
