
  import { mapGetters } from 'vuex';
  export default {
    props: {
      remove: {
        type: Function,
        required: true,
      },
      item: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapGetters({
        storeInformation: 'storeInformation',
      }),
    },
    methods: {
      previewImage(file) {
        const media = (this.items || []).filter((file) => !!file.fileThumbLocation);
        const index = media.findIndex((m) => m.fileLocation === file.fileLocation);
        this.$previewFiles(media, index);
      },
    },
  };
