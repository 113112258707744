
  export default {
    name: 'UploadArtworksList',
    props: {
      folder: {
        type: String,
      },
    },
    components: {
      MPowerValidateInput: () => import('../MPowerValidateInput'),
    },
    data() {
      return {
        isLoading: false,
        link: '',
        errorMessage: null,
      };
    },
    methods: {
      async onChangeLink() {
        await this.$validator.validateAll();

        if (this.formIsInvalid) {
          return;
        }

        try {
          this.isLoading = true;
          this.errorMessage = null;
          const bucketToSave = this.folder ? `${this.folder}/` : null;
          const body = { url: this.link, bucketToSave };
          const { file } = (await this.$axios.post(`/upload/from-link`, body)).data;
          if (file) {
            this.$emit('select', Object.assign({}, file, { alreadyLoaded: true }));
          }
          this.link = '';
          this.errors.clear();
        } catch (e) {
          const { message } = e.data || (e.response && e.response.data) || {};
          this.errorMessage = message;
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
