
  export default {
    name: 'ProductDesign',
    components: {
      DesignModal: () => import('./DesignModal'),
    },
    methods: {
      openModal() {
        this.$refs.designModal.$refs.designs.show();
      },
      handleSelect(design) {
        this.$emit('selectedDesign', design);
        this.$refs.designModal.closeModal();
      },
    },
  };
