
import { mapGetters } from 'vuex';
const Cookie = process.client ? require('js-cookie') : undefined;

export default {
  // inject: ['$validator'],
  props: {
    customerData: Object,
    submitting: Boolean,
    loading: Boolean,
    error: Object | String,
  },
  mounted() {
    const accountManager = Cookie.getJSON('account_manager');
    if (accountManager) {
      this.customerData.userId = accountManager;
    }
  },
  computed: {
    ...mapGetters({
      storeInformation: 'storeInformation',
    }),
    formIsInvalidLocal() {
      return this.errors?.first('Email') || this.errors?.first('password');
    },
  },
  methods: {
    async submit(e) {
      await this.$validator.validateAll();

      if (this.formIsInvalidLocal) {
        return;
      }
      this.$emit('signin', e);
    },
  },
  components: {
    SigninWithSSO: () => import('@/components/signin/SigninWithSSO'),
    MPowerValidateInput: () => import('@/components/MPowerValidateInput'),
    PasswordInput: () => import('../PasswordInput'),
  },
};
