
export default {
  name: 'UploadList',
  props: {
    url: {
      type: String,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    UploadFileListItem: () => import('./UploadFileListItem.vue'),
  },
};
