
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      icons: {
        azure: 'fa-microsoft',
        sso: ' fas fa-users',
      },
    };
  },
  props: {
    containerClass: Object | String,
    btnClass: Object | String,
  },
  computed: {
    ...mapGetters(['storeInformation']),
    socialsAuthCreds() {
      return this.storeInformation.socialsAuthCreds || [];
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    authLink(auth) {
      return `${process.env.API_URL}/customers/authorization/${auth.service}/${this.storeInformation._id}${
        (!this.$route.fullPath.match('signin') && `?redirectUrl=${this.$route.fullPath}`) || ''
      }`;
    },
  },
  mounted() {
    if (!this.$route.fullPath.match('signin')) localStorage.setItem('latestVisitBeforeSign', this.$route.fullPath);
  },
};
