
  import { mapGetters } from 'vuex';

  export default {
    name: 'UploadArtworksList',
    components: {
      MPowerTableClient: () => import('../MPowerTableClient'),
    },
    props: {
      artworks: {
        type: Array,
        required: true,
      },
      type: {
        type: String,
        default: 'list',
      },
    },
    computed: {
      ...mapGetters({
        loggedInCustomer: 'auth/loggedInUser',
      }),
    },
    methods: {
      deleteArtwork(artworkToRemove) {
        const artworkId = artworkToRemove._id;
        const params = {
          artworkId,
          customerId: this.loggedInCustomer._id,
        };
        this.$api.customers.deleteCustomerArtwork(params);
        this.artworks.splice(
          this.artworks.findIndex((artwork) => artwork._id === artworkId),
          1
        );
        this.notificateSuccess('Artwork deleted');
      },
      previewFiles(index) {
        if (this.$refs.arts) {
          this.$previewFiles(this.$refs.arts?.$refs.table?.$refs.table?.data, index);
        }
      },
    },
  };
