
  function wait(ms) {
    return (x) => {
      return new Promise((resolve) => setTimeout(() => resolve(x), ms));
    };
  }

  import { mapGetters } from 'vuex';
  import UploadArtworksList from './UploadArtworksList';
  import UploadFilesList from './UploadFilesList';
  import UploadFromLink from './UploadFromLink';

  const STATUS_INITIAL = 0,
    STATUS_SAVING = 1,
    STATUS_SUCCESS = 2,
    STATUS_FAILED = 3;

  export default {
    name: 'UploadButton',
    components: {
      UploadFilesList,
      UploadArtworksList,
      UploadFromLink,
    },
    props: {
      value: {
        type: Array,
        required: true,
      },
      progressTitle: {
        type: String,
      },
      folder: {
        type: String,
      },
      onFilesChange: {
        type: Function,
      },
      loading: {
        type: Boolean,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      uploadViaUrl: {
        type: Boolean,
        default: true,
      },
      artworks: {
        type: Array,
        default: () => [],
      },
      isAccountPage: { type: Boolean, default: false },
      path: {
        type: String,
        default: () => '/convert-to-image',
      },
    },
    data() {
      return {
        progressUpload: 0,
        isOver: false,
        uploadError: null,
        currentStatus: null,
        errorFiles: [],
        storeArtworks: [],
        maxAllowedSize: 50,
      };
    },
    computed: {
      ...mapGetters(['storeInformation']),

      limitCountOfArtworkToUploadPerOrder() {
        return typeof this.storeInformation.limitCountOfArtworkToUploadPerOrder === 'number'
          ? this.storeInformation.limitCountOfArtworkToUploadPerOrder
          : Infinity;
      },
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING || this.loading;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      },
      hasArtworks() {
        return this.artworks.length > 0;
      },
    },
    methods: {
      previewImage(file) {
        const media = (this.value || []).filter((file) => !!file.fileThumbLocation);
        const index = media.findIndex((m) => m.fileLocation === file.fileLocation);
        this.$previewFiles(media, index);
      },
      onDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        this.isOver = false;
        this.filesChange('files[]', e.dataTransfer.files);
      },
      resetImageUploader() {
        this.$refs.uploadInput.value = '';
      },
      reset() {
        this.currentStatus = STATUS_INITIAL;
        this.uploadError = null;
      },
      async save(formData) {
        this.currentStatus = STATUS_SAVING;

        this.progressUpload = 0;
        const newFormData = new FormData();
        newFormData.append('bucketToSave', `${this.folder}/`);
        newFormData.append('keepOrigin', true);
        newFormData.append(`fileType`, 'Non Production Ready');

        for (const [key, value] of formData.entries()) {
          if (key === 'files[]' && this.isAccountPage) {
            newFormData.append('file', value);
          } else {
            newFormData.append(key, value);
          }
        }
        let res = (
          await this.$axios.post(this.path, newFormData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
              this.progressUpload = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2);
            },
          })
        ).data;
        if (!this.isAccountPage) res = res.files;
        console.log('res ', res);
        res.forEach((i) => {
          this.addFile(i);
        });
        this.currentStatus = STATUS_SUCCESS;
        this.$emit('uploaded');
      },
      filesChange(fieldName, f) {
        const fileList = [].map
          .call(f, (el) => {
            el.location = URL.createObjectURL(el);
            return el;
          })
          .filter((e, index) => index < this.limitCountOfArtworkToUploadPerOrder);

        this.fileCount = fileList.length;
        if (this.onFilesChange) return this.onFilesChange(fileList);
        this.errorFiles = Array.from(fileList).filter((f) => f.size / 1000000 > this.maxAllowedSize);
        if (this.errorFiles.length) return;
        const formData = new FormData();

        if (!fileList.length) return;

        Array.from(Array(fileList.length).keys()).map((x) => {
          formData.append(fieldName, fileList[x]);
        });

        this.save(formData);
      },
      addFile(file) {
        this.value.push(file);
      },
      addArtwork(artwork) {
        const [, fileExt] = artwork.location.match(/\.([\w]+)$/) || [];
        this.addFile({
          fileThumbLocation: artwork.locationResized,
          fileLocation: artwork.location,
          fileExt,
          ...artwork,
        });
      },
    },
    async mounted() {
      this.reset();
    },
  };
