
export default {
  name: 'DesignModal',
  components: {
    StoreDesigns: () => import('../StoreDesigns.vue'),
  },
  methods: {
    closeModal() {
      this.$refs.designs.hide();
    },
  },
};
