import { render, staticRenderFns } from "./UploadArtworksList.vue?vue&type=template&id=75ebbfcb"
import script from "./UploadArtworksList.vue?vue&type=script&lang=js"
export * from "./UploadArtworksList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtImg: require('/codebuild/output/src138728670/src/client/components/NuxtImg.vue').default,DeleteButton: require('/codebuild/output/src138728670/src/client/components/DeleteButton.vue').default,MPowerTableClient: require('/codebuild/output/src138728670/src/client/components/MPowerTableClient.vue').default})
