
  export default {
    name: 'DeleteButton',
    props: ['title', 'record', 'remove', 'variant', 'btnText', 'hideIcon', 'btnClass'],
    data() {
      return {
        loading: false,
      };
    },
    methods: {
      async accept() {
        try {
          this.loading = true;
          await this.remove();
          this.cancel();
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      },
      cancel() {
        this.$refs.container.hide();
      },
    },
  };
