
  import topTableImportMixin from '@/plugins/topTableImportMixin';
  import { validateInputImportMixin } from '@/plugins/validate-input';

  export default {
    name: 'StoreDesigns',
    mixins: [topTableImportMixin, validateInputImportMixin],
    components: {
      StoreDesignsList: () => import('@mtntop/utils/src/components/StoreDesignsList'),
    },
  };
